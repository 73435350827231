<template>
	<div class="facility-card">
		<div v-if="!iscoming" class="img-wrapper">
			<img :src="baseApiUrl + data['image']+'?width=600'" alt="" />
			<div v-show="!data['status']['open']" class="overlay"></div>
			<p v-if="!data['status']['open']">
				{{ data["status"]["text"] }}
			</p>
			
		</div>
		<div class="text-wrapper" :class="{ active: isActive }">
			<div class="text-container text-desktop">
				<div>
					<p class="location">
						{{ data["location"] }}
					</p>
					<p class="name">
						{{ data["name"] }}
					</p>
					<p class="lanes">
						{{ data["lanes"]}} banor
					</p>
				</div>
				<div>
					<a
						v-if="data['status']['open']"
						:href="data['booking_url']"
						target="_blank"
						class="btn"
						@click ="executeTagmanager(data.name)"
					>boka
					</a>
					
					<!--<a v-else href="" class="btn btn-off">kommande</a>-->
					<p v-if="!data['status']['open'] && iscoming && data['status']['text'] && data['status']['text'].length > 0" style="white-space: nowrap;font-size: 14px;color: rgba(0,0,0,0.5);">
						{{ data["status"]["text"] }}
					</p>
					<p v-if="!data['status']['open'] && iscoming && data['status']['text'] && data['status']['text'].length == 0" style="white-space: nowrap;font-size: 14px;color: rgba(0,0,0,0.5);text-align: right">
						Kommande
					</p>
					<p
						v-if="data['status']['open']"
						class="readmore"
						:class="{ active: isActive }"
						@click="readmore"
					>
						visa mer
					</p>
					<a
						v-if="!data['status']['open']"
						class="btn-inverted"
						:href="data['info_url']"
					>
						Läs mer
					</a>
				</div>
			</div>
			<div class="text-container text-mobile">
				<div>
					<p class="location">
						{{ data["location"] }}
						<span v-if="!data['status']['open'] && iscoming && data['status']['text'] && data['status']['text'].length > 0" style="font-family:Proximanova Semibold, sans-serif;white-space: nowrap;font-size: 10px;color: rgba(0,0,0,0.5);">
							{{ data["status"]["text"] }}
						</span>
						<span v-if="!data['status']['open'] && iscoming &&  (!data['status']['text'] ||  data['status']['text'].length == 0)" style="font-family:Proximanova Semibold, sans-serif;white-space: nowrap;font-size: 10px;color: rgba(0,0,0,0.5);">
							Kommande
					
						</span>

					<p class="name">
						{{ data["name"] }}
					</p>
					<p class="lanes">
						{{ data["lanes"]}} banor
					</p>
					<!--<a v-if="!data['status']['open']" href="" class="btn btn-off"
					>kommande</a
					>-->
					
					<p
						v-if="data['status']['open']"
						class="readmore"
						:class="{ active: isActive }"
						@click="readmore"
					>
						visa mer & boka
					</p>
					<a
						v-if="!data['status']['open']"
						class="btn-inverted"
						:href="data['info_url']"
					>
						Läs mer
					</a>
				</div>
			</div>
			
			<div
				:v-if="data['status']['open']"
				class="paragraph-wrapper"
				:class="{ active: isActive }"
			>
				<p v-html="data['paragraph']"></p>
				<p class="club-info">
					<a :href="data['info_url']" target="_blank"
					>Läs mer om klubben här.</a
					>
				</p>
				<ul :v-if="data['listitems']">
					<li v-for="(item, i) in data['listitems']" :key="i">
						<span class="icon"></span> {{ item }}
					</li>
				</ul>
				<div class="address-wrapper">
					<p>{{ data['name'] }}</p>
					<p>{{ data['address'] }}</p>
					<a
						:href="
							'https://www.google.com/maps?q=' +
								encodeURIComponent(data['address'])
						"
						target="_blank"
					>
						<span class="icon"></span>
						<p>Väganvisning</p>
					</a>
				</div>
				<div>
					<a
						v-if="data['status']['open']"
						:href="data['booking_url']"
						target="_blank"
						class="btn btn-mobile"
						@click ="executeTagmanager(data.name)"
					>boka
					</a>
					<p
						class="readmore readless"
						:class="{ active: !isActive }"
						@click="readmore"
					>
						visa mindre
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { baseUrl } from '@/assets/apiLink.js'
export default {
	name: 'FacilityCard',
	props: {
		data: {
			type: Object,
			required: true,
		},
		iscoming: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			isActive: false,
			baseApiUrl: baseUrl,
		}
	},
	methods: {
		readmore: function (event) {
			this.isActive = !this.isActive
		},
		executeTagmanager: function (name) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				event: 'boka bana',
				bana: name, // Ersätt med vilken bana som de facto bokas
			})
			if (name.length > 0) {
				registerD7()
			}
		},
	},
}
</script>
<style scoped lang="scss">
.facility-card {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
	height: fit-content;

	.img-wrapper {
		@include aspect-ratio(2.2, 1);

		max-height: 205px;
		min-height: 205px;

		.overlay {
			background: rgba(0, 0, 0, 0.4);
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 100%;
		}

		p {
			@include paragraph-link;

			font-family: $secondary-font-sbold;
			text-align: end;
			right: 22px;
			position: absolute;
			bottom: 12px;
			color: #fff;
			text-transform: uppercase;
		}

		@include max-width(small) {
			max-height: 130px;
			min-height: 130px;
			&::before {
				padding-top: 0;
			}
		}
	}

	.name {
		color: #343735;
		font-family: $secondary-font-sbold;
		text-transform: uppercase;
		@include max-width(small) {
			font-size: 15px;
		}
	}

	.location {
		color: #343735;
		margin-top: 3px;
		font-family: $primary-font; //$secondary-font-sbold;
		text-transform: uppercase;
		font-size: 40px;
		letter-spacing: 0.7px;
		margin-bottom: 12px;
		@include max-width(medium) {
			font-size: 25px;
		}
	}
	.lanes {
		opacity: 0.5;
		font-size: 14px;
		font-weight: 600;
		margin-top: 8px;
	}
	.text-wrapper {
		padding: 22px 22px 0 22px;
		background-color: #fff;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		max-height: 200px;
		min-height: 146px;
		overflow: hidden;
		height: auto;

		&.active {
			max-height: 999px;
			padding-bottom: 10px !important;
		}

		@include max-width(small) {
			padding: 14px 16px 6px 13px;
			min-height: unset;
		}
	}

	.text-container {
		display: flex;
		justify-content: space-between;

		& > div:nth-of-type(1) {
			margin-right: auto;
		}
	}
	.text-desktop {
		display: flex;

		@include max-width(medium) {
			display: none;
		}
	}
	.text-mobile {
		display: none;

		@include max-width(medium) {
			display: flex;
		}

		& > div:first-child {
			width: 100%;
			margin-right: 0;
			display: flex;
			flex-direction: column;
		}
		.readmore {
			margin-top: -1px;
			padding: 10px 0;
			align-self: flex-end;
			color: $color-green;
			line-height: initial;
		}
		.location {
			max-width: 100%;
			margin-top: 0;
		}
		.btn-off {
			width: fit-content;
			align-self: flex-end;

			@include max-width(medium) {
				position: relative;
				bottom: 12px;
			}
		}
	}
	.btn {
		@include paragraph-link;

		max-height: 40px;
		display: flex;
		align-items: center;
		padding: 13px 25px;
		border-radius: 25px;
		background-color: $color-green;
		text-transform: uppercase;
		color: #fff;
	}
	.btn-inverted {
		@include paragraph-link;

		max-height: 40px;
		display: flex;
		align-items: center;

		border-radius: 25px;
		border: 2px solid $color-green;
		background-color: #fff;
		text-transform: uppercase;
		color: $color-green;
		width: 96px;
		justify-content: center;
		margin-left: auto;
		margin-top: 45px;
		@include max-width(medium) {
			margin-top: -10px;
		}
	}
	.btn-off {
		color: $color-grey-btntext;
		background-color: $color-bg-grey;
		cursor: default;
		pointer-events: none;
	}

	.readmore {
		@include paragraph-link;

		cursor: pointer;
		margin-top: 20px;
		margin-bottom: 10px;
		font-family: $secondary-font-sbold;
		text-transform: uppercase;
		text-align: center;

		&.active {
			display: none;
		}
	}

	.readless {
		text-align: end;
		margin: 17px 12px 5px 0;

		@include max-width(small) {
			margin-bottom: 0;
			margin-right: 5px;
		}
	}

	.paragraph-wrapper {
		display: none;
		max-width: 0;
		max-height: 0;
		transition: all 0.2s ease;

		& > p:nth-of-type(1) {
			margin-top: 15px;
		}

		& > p:nth-of-type(1),
		li,
		.club-info a {
			@include paragraph-small;

			letter-spacing: normal;
			color: $color-grey-dark;
			line-height: 22px;
			margin-bottom: 10px;
			align-items: center;

			span {
				height: 30px;
				width: 30px;
				margin-right: 15px;
				background-image: url('../assets/images/Bock.svg');
			}
		}
		li,
		.club-info a {
			display: flex;
		}
		.club-info a {
			color: $color-blue;
			font-family: $secondary-font-sbold;
			margin-top: -7px;
		}

		ul {
			margin-top: 20px !important;

			li {
				margin-bottom: 8px;
			}
		}

		.address-wrapper {
			margin-top: 26px;

			p {
				font-size: 14px;
			}

			a {
				margin-top: 8px;
				display: flex;
				align-items: center;
				p {
					font-family: $secondary-font-sbold;
					color: $color-blue;
				}
				span {
					height: 16px;
					width: 16px;
					margin-right: 8px;
					background-image: url('../assets/images/mapicon_blue.svg');
				}
			}
		}

		& > div:nth-of-type(2) {
			display: flex;
			justify-content: flex-end;

			@include max-width(small) {
				margin-top: 20px;
				justify-content: space-between;
			}
		}

		&.active {
			display: initial;
			max-height: fit-content;
		}

		.btn-mobile {
			min-width: 160px;
			text-align: center;
			display: flex;
			justify-content: center;
			display: none;

			@include max-width(small) {
				display: flex;
			}
		}
	}
}
</style>
